import PropTypes from 'prop-types'
import React from 'react'
import info from '../images/info.jpg'
import SoldCar from './SoldCar'


class Main extends React.Component {

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Info</h2>
          <span className="image main">
            <img src={info} alt="" />
          </span>
          <p>
            Vi tar oss av:
          </p>
          <ul>
            <li>Bilder og annonse</li>
            <li>Forhandlinger</li>
            <li>Papirarbeid</li>
            <li>Pengeoverførsel</li>
            <li>Levering av bil til kjøper</li>
          </ul>
          <p>Vi tilbyr:</p>
          <ul>
            <li>Billån</li>
            <li>Bilforsikring</li>
            <li>Salgs og kjøpers garanti</li>
            <li>Gode priser på klargjøring</li>
            <li>Garanti på bilene som selges</li>
            <li>Gode priser på skifte og reperasjon av bilglass</li>
          </ul>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Biler til salgs</h2>
          <iframe src="https://www.finn.no/pw/search/car-norway?orgId=5446226" width="100%" height="850px" title="Biler til salgs"></iframe>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Salgsarkiv</h2>
            <SoldCar></SoldCar>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Kontakt oss</h2>
          <h4>Erik</h4> <p>94 26 00 00 - erik@skrettingbil.no</p>
          <h4>Adresse:</h4> <p>Sømsveien 212, 4638 Kristiansand</p>
          <ul className="icons">
            <li>
              <a
                href="https://www.facebook.com/Skrettingbil"
                className="icon fa-facebook"
              >
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/skrettingbilsalgshjelpen/" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
