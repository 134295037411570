 import React from "react";
 import { StaticQuery, graphql } from "gatsby";

 const SoldCar = () => (
   <StaticQuery
     query={graphql`
     query{
      salgshjelp{
       listSalgshjelps(limit: 1000){
        items{
          title
          description
          url
          image
        } 
      } 
      }
    }
     `}
     render={({
      salgshjelp: {
        listSalgshjelps: {
          items
        }
      }
      
     }) => (
       <>
       <input type="text" id="myInput" onChange={event => {
        var filter, ul, li, a, i, txtValue;
        filter = event.target.value.toUpperCase();
        ul = document.getElementById('soldCars');
        li = ul.getElementsByTagName('li');

        // Loop through all list items, and hide those who don't match the search query
        for (i = 0; i < li.length; i++) {
          a = li[i].getElementsByTagName("h2")[0];
          txtValue = a.textContent || a.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
          } else {
            li[i].style.display = "none";
          }
        }
    }} placeholder="Søk i arkivet" style={{marginBottom: '20px'}}></input>
       
       <ul id="soldCars" style={{listStyle: 'none', padding: '0'}}>
       {
         items.map((item, index) => (
           <li>
           <h2>{item.title}</h2>
           <span className="image main">
              <img src={item.image} alt="" />
            </span>
           <p>{item.description}</p>
           <a href={item.url} target="_blank" rel="noopener noreferrer"><button>Se annonsen</button></a>
           <hr></hr>
           </li>
         ))
       }
       </ul>
       </>
     )}
   />
 );

 
 
 export default SoldCar;
